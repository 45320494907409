.footer-ul {
    padding-left: 0px;
    li {
        list-style-type: none;
        margin-bottom: .6rem;
        a {
            color: white;
            &:hover {
              color: white;
              cursor: pointer;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer-ul {
        li {
            a {
                font-size: .9em;
            }
        }
    }
}
footer.main-footer {
    @include media-breakpoint-down(sm) {
    }
    p {
        color: #888;
        font-size: 13px;
        line-height: 1.4;
    }
}
.main-footer { 
    background-color: #535555;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    @include media-breakpoint-up(lg) {
        padding: 2.2rem 2.2rem 1rem 2.2rem;
    }
}
.main-footer__item {
    color: #fff;
    font-size: 1.4em;
}
.main-footer__newsletter {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}
.main-footer__copyright {
    text-align: right;
}
.secondary-footer {
    padding: 1rem;
    @include media-breakpoint-up(md) {
        padding: 1rem 3.5rem;
    }
}
.payment-icons {
    text-align: right;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 1rem;
    i.pf {
        font-size: 18px;
        display: inline-block;
        margin-left: 10px;
    }
}
.payment-icons__img {
    float: right;
    width: 305px;
    height: 35px;
}

.smf__item {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(xl) {
        width: 60px;
        height: 60px;
        margin-right: 1rem;
    }
}
.social-media-footeer,
.main-footer__newsletter {
    margin-left: 0rem;
    @include media-breakpoint-up(lg) {
    }
    @include media-breakpoint-up(xl) {
    }
    .btn {
        border-radius:1px;
        text-transform: uppercase;
        font-size: 13px;
        padding:15px 20px;
    }
    .form-control {
        max-width:15rem;
        width:100%;
    }
}
.social-media-footeer {
        a {
            margin:10px 3px;
            @include media-breakpoint-down(sm) {
                margin:4px 2px;
            }
        }
}
.form-control {
    border-radius:1px;
}

footer {
    .footer-countries {
        text-align: center;
        margin-top: -15px;
        margin-bottom: 5px;
        .flag {
            display: inline-block;
            position: relative;
            margin: 0 5px;
            object {
                position: absolute;
                top: 0;
                left: 0;
                width: 22px;
                height: 16px;
                pointer-events: none;
            }
        }
    }
}