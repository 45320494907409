/*
Helper classes
*/
.is-source-sans {
  font-family: $source-sans-pro;
  -webkit-font-smoothing: antialiased;
}
.is-raleway {
  font-family: $raleway;
  -webkit-font-smoothing: antialiased;
}
.is-size-15 {
  font-size: 15px;
}
.is-size-16 {
  font-size: 16px;
}
.is-size-18 {
  font-size: 18px;
}
.is-size-22 {
  font-size: 18px;
}
.is-w-10 {
  width: 10rem;
}
.is-w-15 {
  width: 15rem;
}
.is-uppercase {
  text-transform: uppercase;
}
.is-size-24 {
  font-size: 24px;
}
.is-lh-16 {
  line-height: 16px;
}
.is-lh-40 {
  line-height: 40px;
}
.is-square-60 {
  width: 60px;
  height: 60px;
}
.is-dark-input {
  background-color: #242424;
  border: 1px solid #242424;
  color: #acabab;
}
.is-valign-middle {
  
}
.is-centered {
  text-align: center;
}
.m-l-1 {
  margin-left: 1rem;
}
.m-l-4 {
  margin-left: 4rem;
}
.m-b-05 {
  margin-bottom: 0.5rem;
}
.semibold {
  font-weight: 600;
}
.has-dark-cover {
  color: white;
}
.is-inline-block {
  display: inline-block;
}
.is-fixed-mobile {
  position: fixed;
  z-index: 500;
  z-index: 3000;
  width: 100%;
  top:0;
  left:0;
  right:0;
  margin:0;
}
