// Below override values defined in ./node_modules/bootstrap/scss/_variables.scss
// Remove what you do not use as an override
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1070px,
  // Extra large screen / wide desktop
  xl: 1404px
);

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 1100px,
  xl: 1334px
);

// Grid columns
$grid-columns:               12;
$grid-gutter-width:          1.875rem; // 30px

// Typography
$font-family-sans-serif:     'Source Sans Pro', sans-serif;
$font-family-serif:          Georgia, "Times New Roman", Times, serif;
$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:           $font-family-sans-serif;

$font-size-root:             16px;

$font-size-base:             1rem;
$font-size-lg:               1.25rem;
$font-size-sm:               .875rem;
$font-size-xs:               .75rem;


$font-size-h1:               2.5rem;
$font-size-h2:               2rem;
$font-size-h3:               1.75rem;
$font-size-h4:               1.5rem;
$font-size-h5:               1.25rem;
$font-size-h6:               1rem;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;

$line-height:                 1.5;

// typography
$source-sans-pro: 'Source Sans Pro', sans-serif;
$raleway: 'Raleway', sans-serif;
// colors
$dark-gray: #505151;









