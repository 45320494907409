// global styles h,p,spans
body {
  -webkit-font-smoothing: antialiased;
}
html {
  h1 {
    font-weight: 300;
  }
  h1, h2, h3, h4, h5 {
    font-weight: 300;
  }
  h1, h2, h3, h4, h5, p, span {
    color: #535555;
  }
  a:hover, a:focus {
  	text-decoration:none;
  }
  p {
  	margin-bottom:1.25rem;
  }
  p.larger_p {
  	font-size:1.4em;
  	line-height:1.25;
  }
}




