// SMACSS asset organisation
@import "base/variables";
@import "app-vendor/bootstrap_v4_config";
@import "base/functions";
@import "base/mixins";
@import "base/helpers";
@import "base/base";
@import "layout/footer";
@import "layout/header";
@import "layout/main";
@import "layout/navigation";
@import "notie";
@import "layout/sidebar";
// File only used to bootstrapping assets, do not write code in here
