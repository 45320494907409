/*
Body layout content selectors
*/

$accent-color: #eea9cf;
@mixin prefix($property, $value) {
    $prefixes: moz, o, ms, webkit;
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    // Output standard non-prefixed declaration
    #{$property}: $value;
}
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}
.text-left  {
    text-align:left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
@media(max-width:768px) {
    .text-left-mobile {
        text-align:left !important;
    }
    .text-center-mobile {
        text-align:center !important;
    }
    .text-right-mobile {
        text-align:right !important;
    }
}
.small-p {
    font-size: 11px;
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline:none;
}
.btn {
    display: inline-block;
    white-space:auto;
    padding:8px 25px;
    border-radius: 1px;
    border:none;
    max-width:100%;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.08),0 2px 10px 0 rgba(0,0,0,0.08);
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    border-radius:2px;
    &:focus, &:active, &:active:focus {
        outline:none;
        border-color:transparent;
        box-shadow:none;
        outline:none;
    }
    &.btn-small {
        font-size: 12px;
        padding:6px 12px;
        .icon.icon-left {
            margin-right:3px;
            position: relative;
            top:2px;
        }
    }
    &.btn-default {
        color: #fff;
        background: $accent-color;
        &:hover {
            background: $accent-color;
            color: #fff;
            box-shadow:0px 0px 6px rgba(0,0,0,.2);
        }
    }
    &.btn-gray {
        color: #fff;
        background: #888;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        &:hover {
            background: #aaa;
            color: #fff;
            box-shadow:0px 0px 6px rgba(0,0,0,.2);
        }
    }
    &.btn-light-gray {
        color: #fff;
        background:#aaa;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        &:hover {
            background: #aaa;
            color: #fff;
            box-shadow:0px 0px 6px rgba(0,0,0,.2);
        }
    }
    &.btn-white {
        color: #999;
        background: #fff;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        &:hover {
            background: #aaa;
            color: #fff;
            box-shadow:0px 0px 6px rgba(0,0,0,.2);
        }
        &.btn-small {
            padding:6px 12px;
            font-size: 14px;
        }
    }
    &.btn-filter {
        background: transparent;
        position: relative;
        box-shadow: 0 3px 6px rgba(0,0,0,.06);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 12px;
        overflow: hidden;
        z-index: 1;
        &:before {
            content:'';
            position: absolute;
            top:0;left:0;width:100%;height:100%;
            background: $accent-color;
            -webkit-transform: scaleX(0);
            -ms-transform: scaleX(0);
            -o-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: transform .3s ease;
            -o-transition: transform .3s ease;
            transition: transform .3s ease;
            -webkit-transform-origin: 0 50%;
            -moz-transform-origin: 0 50%;
            -ms-transform-origin: 0 50%;
            -o-transform-origin: 0 50%;
            transform-origin: 0 50%;
            z-index: -1;
        }
        &:hover {
            box-shadow: 0 8px 15px rgba(0,0,0,.15);
        }
        &.active {
            color: #fff;
            
            &:before {
                -webkit-transform: scaleX(1);
                -ms-transform: scaleX(1);
                -o-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
        &:focus {
            outline: none !important;
        }
    }
    &.btn-red {
        background: #cb1f31;
        color: #fff;
    }
    .icon-left {
        margin-right: 5px;
        font-size: 14px;
    }
    &.btn-wide {
        width:100%;
    }
    &.btn-paypal {
        background: #fff;
        border-radius:30px;
        font-size: 12px;
        font-weight: 700;
        color: #111;
        float: right;
        margin-top: 25px;
        box-shadow:0px 3px 7px rgba(0,0,0,.1);
        .text {
            text-shadow:0px 1px 1px rgba(0,0,0,.1);
        }
        img {
            display: inline-block;
            float: left;
            width:20px;
            margin-right: 5px;
        }
        &:hover {
            box-shadow:0px 5px 10px rgba(0,0,0,.2);
        }
    }
    i.icon-left {
        margin-right: 10px;
    }
    i.icon-right {
        margin-left: 10px;
    }
}
a.have-a-coupon {
    display: inline-block;
    color: #999;
    font-weight: 700;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
    &:after {
        content:'';
        position: absolute;
        bottom:-2px;
        height: 2px;
        background: $accent-color;
        width:100%;
        left:0;
        -webkit-transition: transform .3s ease;
        -o-transition: transform .3s ease;
        transition: transform .3s ease;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }
    &:hover {
        color:$accent-color;
        &:after {
            -webkit-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}
a {
    color: $accent-color;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
    &:hover, &:active, &:focus {
        color:$accent-color;
    }
}
a.link {
    display: inline-block;
    font-weight: 700;
    color: #535555;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    position: relative;
    &:after {
        content:'';
        position: absolute;
        bottom:0px;
        height: 2px;
        background: $accent-color;
        width:100%;
        left:0;
    }
    &:hover {
        color:$accent-color;
    }
}
.section-title {
    position: relative;
    text-align: center;
    >h1 {
      position: relative;
      min-width: 20rem;
      margin: 0px auto;
      z-index: 2;
      background-color: white;
  }
}
.section-title-underline {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    margin: 0px;
    color: $dark-gray;
}
.primary-navigation--first-level {
    background: #fff;
    -webkit-transition: transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    @include media-breakpoint-down(sm) {
        -webkit-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    z-index:1;
    position: relative; 
    &.active {
        -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }
}
main.main-wrapper {
    @include media-breakpoint-down(sm) {
        padding:0;
    }
}
.showcase-banner__item--left,
.showcase-banner__item--right {
    background-color: $accent-color;
    min-height: 350px;
}
.showcase-banner__cell {
    min-height: 50px;
    text-transform: uppercase;
}
.showcase-banner__quality {
    background-image: url(/img/Quality.png);
    @include banner-units;
}
.showcase-banner__delivery {
    background-image: url(/img/Delivery.png);
    @include banner-units;
}
.showcase-banner__payment {
    background-image: url(/img/Security.png);
    @include banner-units;
}
.showcase-banner__csupport {
    background-image: url(/img/Support.png);
    @include banner-units;
}
.showcase-banner__item--full  {
    //margin-top: 1rem;
}
.showcase-banner__cell {
    padding-top: 1rem;
    text-align: center;
}

.breadcrumbs {
    padding:0px 0;
    margin:0;
    list-style:none;
    li {
        display: inline-block;
        &:not(:last-child):after {
            content: '>';
            color: $accent-color;
        }
        a {
            color: $accent-color;
            display: inline-block;
            font-size: 14px;
            padding:5px 4px;
            font-weight: 500;
            border-radius:0;
            -webkit-transition: all .2s ease;
            -o-transition: all .2s ease;
            transition: all .2s ease;
        }
    }
    .separator {
        display: inline-block;
        position: relative;
        top:2px;
        padding: 0 3px;
    }
    @include media-breakpoint-down(sm) {
        padding-left:20px;
    }
    @media(max-width:768px) {
        padding: 30px 20px;
        padding-bottom: 0px;
        li > a {
            font-size: 11px;
        }
    }
}
.section__main_title {
    text-align: center;
    display: block;
    text-align: center;
    margin: 40px 0;
    padding-top:20px;
    padding-bottom: 20px; 
    >.heading, >h1 {
        display: inline-block;
        padding: 5px 30px;
        position: relative;
        z-index:1;
        color: $accent-color;
        font-weight: 600;
        margin:0;
        @media (max-width:768px) {
            font-size: 18px;
        }
    }
}
.section__main {
    margin-bottom: 40px;
    border:1px solid #eee;
    @include media-breakpoint-down(sm) {
        box-shadow: none;
    }
    img.section__main_banner {
        margin-bottom: 40px;
        width:100%;
        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;    
        }
    }
    .section__main_container {
        width:100%;
        padding:20px 40px;
        @include media-breakpoint-down(sm) {
            padding:0 25px;
        }
    }
}
.social-media-footeer { 
    margin-bottom: 20px;
    a {
        color: #fff;
        font-size: 18px;
        display: inline-block;
        width:40px;
        height:40px;
        text-align: center;
        line-height: 38px;
        border-radius: 50%;
        border:2px solid #fff;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        &:hover {
            background: #fff;
            box-shadow:0px 3px 10px 7px rgba(0,0,0,.15);
            color: $accent-color;
        }
    }
}
.main-footer__newsletter {
    .btn {
        background: $accent-color;
        border:none;
        outline:0;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        padding:9px 20px;
        font-weight: 700;
        margin-top: -4px;
        vertical-align: auto;
        &:hover {
            background: #e19dc1;
            color: #fff;
            box-shadow:0px 1px 6px 5px rgba(0,0,0,.15);
        }
        &:active {
            background: #61af1e;
            border:none;
            outline:0;
            box-shadow:none;
            box-shadow:0px 1px 6px 5px rgba(0,0,0,.15);
        }
    }
    .form-control {
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        margin-bottom: 10px;
        &:focus {
            outline:0;
            border-color:transparent;
            box-shadow:none;
            box-shadow:0px 1px 6px 5px rgba(0,0,0,.15);
        }
    }
}
.main-footer ul > li {
    width:100%;
    >a {
        -webkit-transition: transform .25s ease;
        -o-transition: transform .25s ease;
        transition: transform .25s ease;
        display: inline-block;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        position: relative;
        font-weight: 700;
    &:after {
        content:'';
        position: absolute;
        bottom:-2px;
        height: 2px;
        background: $accent-color;
        width:100%;
        left:0;
        -webkit-transition: transform .3s ease;
        -o-transition: transform .3s ease;
        transition: transform .3s ease;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }
    &:hover {
        color:$accent-color;
        &:after {
            -webkit-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    }
}
.burger-menu__wrapper  {
    position: relative;
    z-index: 5;
    background:#fff;
    .logo {
        float: left;
        margin-left: 56px;
    }
}

/* Form */
.form-mat {
    padding:15px 0px 30px; 
    margin:20px 0;
    .form-heading {
        display: block;
        margin:0 auto;
        text-align: center;
        border-bottom:1px solid #dfdfdf;
        margin-bottom: 20px;
    }
    .form-content {
        padding:0 20px;
    }
}

.form-component {
        position:relative;
        padding:20px 0 10px;
        &.form-component-checkbox {
            padding:15px 0 10px;
        }
        &.form-component-required {
            label:after {
                content:'*';
                font-weight:700;
                color: $accent-color;
                font-size: 1.25em;
                display: inline-block;
                margin-left: 5px;
            }
        }
        .validation-msg {
            display: inline-block;
            margin-top: 10px;
            font-size: .8em;
            color: #F44336;
            font-weight: 700;
        }
        label {
            cursor:default;
            font-weight: 700;
            color: #999;
            &.normal {
                position: relative;
                top:auto;
            }
        }
        .form-control {
            background: white;
            border: none;
            padding: 10px 10px;
            height: auto;
            border-color: #eea9cf;
            border-style: dashed;
            border-width: medium;
            &:focus {
                border-color: #eea9cf;
                box-shadow: none;
                outline:none;
            }
            
        }
        .form-control.active+label {
        }
        &.form-component-dropdown {
            position: relative;
            &:after {
                width:0;
                height:0;
                border:5px solid transparent;
                border-top-color:#aaa;
                content:'';
                position: absolute;
                right:14px;
                top:67px;
            }
            input {
                padding-right: 30px;
                background: #fff;
            }
            .__select {
                display: none;
            }
            ul {
                list-style: none;
                background:#fff;
                padding:0; margin:0;
                display: none;
                position: absolute;
                top: 93px;
                 -webkit-animation: fadeInUp;
                animation: fadeInUp;
                -webkit-animation-duration: .5s;
                animation-duration: .5s;
                -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
                width:100%; 
                z-index:100;
                box-shadow:0px 0px 5pxpx rgba(0,0,0,.07);
                max-height: 170px;
                overflow-y: auto;
                li {
                    cursor:pointer;
                    padding:5px 10px; 
                    -webkit-transition: background-color .4s ease;
                    -o-transition: background-color .4s ease;
                    transition: background-color .4s ease;
                    &:hover {
                        background:#eee;
                    }
                }
            }
        }
        .forgot-password {
            font-size: .85em;
            margin-top: 5px;
            display: inline-block;
        }
    }

.steps {
    padding:30px 0px; 
    display: block;
    text-align: center;
    @include media-breakpoint-down(sm) {
        padding:0;
    }
    .row {
        margin-left:-5px;
        margin-right: -5px;
    }
    ul {
        list-style: none; 
        padding:40px 0;
        margin:0;
        li {
            display: inline-block;
            margin:10px 0px;
            padding:0 5px;
            text-align: center;
            a {
                display: inline-block;
                width:100%;
                text-align: center; 
                .icon {
                    font-size: 48px;
                    display: block;
                    color: #999;
                    -webkit-transition: color .4s ease;
                    -o-transition: color .4s ease;
                    transition: color .4s ease;
                    @media(max-width:768px) {
                        font-size: 25px;
                    }
                }
                &.current .icon, &:hover .icon {
                    color:$accent-color;
                }
                span {
                    font-weight: 700;
                    color: #999;
                    font-size: 18px;
                    display: block;
                    margin-top: 15px;
                    -webkit-transition: color .4s ease;
                    -o-transition: color .4s ease;
                    transition: color .4s ease;
                    @media(max-width:768px) {
                        font-size: .85em;
                        margin-top: 5px;
                    }
                }
                &.current span, &:hover span {
                    color:$accent-color;
                }
                &.disabled {
                    pointer-events: none;
                    cursor: default;
                    i, span {
                        color: #ddd !important;
                    }
                }
            }
        }
    }
}
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  top:0px;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left:0; top: 2px;
  width: 20px; height: 20px;
  border: 1px solid #aaa;
  background: #fff;
  border-radius: 0%;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 15px;
    top: 5px;
    left: 3px;
    background: $accent-color;
    transition: all .2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1; 
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
}

/* hover style just for information */
label:hover:before {
}


[type="radio"] {
    display: none;
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
}
[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  top:0;
}

/* checkbox aspect */
[type="radio"]:not(:checked) + label:before,
[type="radio"]:checked + label:before {
  content: '';
  position: absolute;
  left:0; top: 2px;
  width: 20px; height: 20px;
  border: 1px solid #aaa;
  background: #fff;
  border-radius: 50%;
}
/* checked mark aspect */
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:after {
    font-family: ElegantIcons;
    content: "";
    position: absolute;
    width:10px;
    height: 10px;
    top: 7px;
    left: 5px;
    background: $accent-color;
    transition: all .2s;
    border-radius: 50%;
}
/* checked mark aspect changes */
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1; 
  transform: scale(1);
}
/* disabled checkbox */
[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="radio"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="radio"]:checked:focus + label:before,
[type="radio"]:not(:checked):focus + label:before {
}

/* hover style just for information */
label:hover:before {
}



.radio-option {
    padding:10px 25px 5px;
    border:1px solid #dcdcdc;
    margin-bottom: 30px;
    border-radius: 2px;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    &:hover {
        background: #eaeaea;
        box-shadow:0px 2px 10px rgba(0,0,0,.07);
    }
    .add-inputs {
        display: none;
        /*-webkit-animation: bounceIn;
        animation: bounceIn;
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        transform-style: flat;

        Intro animations cause a bug where z-index doesn't work.*/
    }
}

.payment-card {
    float: left;
    width:60px;
    height: auto;
    margin-right: 25px;
}

.payment-additional-price {
    font-weight: 700;
    color: #999;
}

.material-shadow {
    box-shadow: 0 1px 2px rgba(0,0,0,.06);
    background: #f3f3f3;
}

.material-shadow-bordered {
    box-shadow: 0 1px 2px rgba(0,0,0,.06);
    background: #f3f3f3;
    border:1px solid $accent-color;
}

.step-navigation {
    padding:20px 30px;
    margin-bottom: 40px;
    a.btn {
        color: #fff;
        display: inline-block;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease; 
        &:active {
            box-shadow: 0 1px 10px rgba(0,0,0,.08 );
        }
        &.btn-prev {
            background: #bbb;
            &:hover {
                background:#ccc;
            }
        }
        &.btn-next {
            background: #535555;
            &:hover {
                background:#444;
            }
        }
    }
}

.number-input-control  {
    display: inline-block;
    position: relative;
    top:1px;
    color: #999;
    background: transparent;
    border:none;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
    &:hover {
        color: $accent-color;
    }
    &:focus {
        outline:none;   
    }
}

.product-container {
    .number-input-control {
        font-size: 24px;
        color: $accent-color;
        &.number-input-lower {
            +.form-control {
                font-size: 24px;
            }
        }
    }
}

.shipping_desc {
    color: #999;
    font-size: 14px;
    margin-bottom: 10px;
}

.address-boxes {
    margin-top: 60px;
    .address-box {
        margin-bottom: 20px;
        .material-shadow {
            padding:20px 25px 20px;
            
        }
        .btn-ship {
            margin-bottom: 15px;
        }
        .title {
            margin-bottom: 5px;
            .heading {
                text-transform: none;
            }
            .change-address {
                color: $accent-color;
            }
        }
        span {
            display: block;
            .method {
                display: inline-block;
                color: #999;
            }
        }
    }
}




$breakpoint-alpha: 1069px; // adjust to your needs


.rwd-table {
  margin: 1em 0;
  min-width: 100%; // adjust to your needs
  
  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    &:nth-child(odd) {
        background: #ddd;
    }
    &:nth-child(even) {
        background: #eee;
    }
  }
  
  th {
    display: none;
  }
  
  td {
    display: block; 
    padding-left: 20px;
    padding-right: 20px;
    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": "; 
      font-weight: bold;

      
      width: 6.5em; 
      display: inline-block;
      // end options
      
      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }
  
  th, td {
    text-align: left;
    
    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: .25em .5em;
      
      &:first-child {
        padding-left: .5em;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }

  }
  
  
}

.form-component input.number-input {
    background: transparent;
    border: none !important;
    padding: 0;
}


.rwd-table {
    margin: 1em 0;
    min-width: 300px; // adjust to your needs
    
    tr {
        &:nth-child(odd) {
            background: #ececec;
        }
        @media (max-width: $breakpoint-alpha) {
            padding:0 20px;
            td:first-child {
                padding-top:.75em;
            }
            td:last-child {
                padding-bottom:.75em;
            }
            .form-component {
                padding:0;
            }
            .product-title {
                margin-top: 0;
            }
            .form-component.form-component-checkbox {
                padding:0;
                position: relative;
                top:-7px;
            }
        }
    }

    tr.bundle {
        td {
            &:nth-child(2), &:nth-child(3), &:nth-child(5) {
                .cell-content {
                    padding-top: 25px;
                }
            }
            &:nth-child(4) {
                .cell-content {
                    padding-top: 18px;
                }
            }
        }
        th, td {
            vertical-align: top;
        }
    }

    th {
        display: none; 
    }

    td {
        display: block; 
        position: relative;
        &:first-child {
            //padding-top: .5em;
        }
        &:last-child {
            //padding-bottom: .5em;
        }

        &:before {
            font-weight: bold;
            position: absolute;
            top:5px;

            
                width: 8.5em; 
                display: inline-block;
                // end options

                @media (min-width: $breakpoint-alpha) {
                    display: none;
                }
            }
        }
        
        .form-component.form-component-checkbox {
            padding:0 0 10px;
            label {
                top:-8px;
                span {
                    display: none;
                }
            }
        }
        .form-component {
            padding:0;
        }
        .cart-product-total {
            font-weight: 700;
            color: #000;
        }
        .product-title {
            color: $accent-color;
            font-weight: 700;
            display: inline-block;
        }
        .product-desc {
            color: #999;
            font-size: 14px;
        }
        .cell-component {
            margin-left: 8.5em
        }
        .cell-qty {
            width:90px !important;
            .number-input {
                width:25px;
                text-align: center;
                display: inline-block;
                border-bottom:0;
                font-size: 18px;
                font-weight: 700;
                color: #999;
            }
            .number-input-control  {
                display: inline-block;
                position: relative;
                top:1px;
                color: #999;
                background: transparent;
                border:none;
                -webkit-transition: color .3s ease;
                -o-transition: color .3s ease;
                transition: color .3s ease;
                &:hover {
                    color: $accent-color;
                }
                &:focus {
                    outline:none;   
                }
            }
        }

        th, td {
            text-align: left;
            padding: .25em .65em;
            
            .cell-content {
                display: inline-block;
                .option {
                    display: block;
                    font-size: 14px;
                    font-weight: 700;
                    color: #888;
                }
                .bundle-title {
                    margin-bottom: 8px;
                    font-weight: 700;
                    margin-top: 20px;
                }
                .bundle-list {
                    list-style: none;
                    padding: 0;
                    >li {
                        margin-bottom: 20px;
                        .bundle-img {
                            width:64px;
                        }
                        .media-body {
                            overflow:visible;
                        }
                        .bundle-heading {
                            font-weight: 700;
                            font-size: 13px;
                        }
                        .bundle-description {
                            color: #999;
                            font-size: 11px;
                            margin-bottom: 5px;
                        }
                        .form-component-dropdown {
                            width:100%;
                            max-width:400px;
                        }
                        .prices {
                            .old-price {
                                color: #bbb;
                                text-decoration: line-through; 
                                margin-right: 10px;
                            }
                            .new-price {
                                color: $accent-color;
                                font-weight: 700;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            @media (min-width: $breakpoint-alpha) {
                display: table-cell;
                padding: .25em .65em;
                .cell-content {
                    display: block;
                    margin-left: 0;
                    width: auto;
                    &.cell-remove {
                        text-align: center;
                    }
                }
                &:first-child {
                    //padding-left: 0;
                }

                &:last-child {
                    //padding-right: 0;
                }
            }

        }

        .cart-product-img {
            width:80px;
            height: auto;
        }
}

@media (max-width: $breakpoint-alpha) {

    .rwd-table:not(.no-product-img) {
        margin: 1em 0;
        min-width: 300px; // adjust to your needs
        
        tr {
            &:nth-child(odd) {
                background: #ececec;
            }
            @media (max-width: $breakpoint-alpha) {
                padding:0 20px;
                td:first-child {
                    padding-top:.75em;
                }
                td:last-child {
                    padding-bottom:.75em;
                }
                .form-component {
                    padding:0;
                }
                .product-title {
                    margin-top: 0;
                }
                .form-component.form-component-checkbox {
                    padding:0;
                    position: relative;
                    top:-7px;
                }
            }
        }

        th {
            display: none; 
        }

        td {
            display: block; 
            position: relative;
            &:first-child {
                //padding-top: .5em;
            }
            &:last-child {
                //padding-bottom: .5em;
            }

            &:before { 
                font-weight: bold;
                position: absolute;
                top:5px;
                content:'';
                
                    width: 8.5em; 
                    display: inline-block;
                    // end options

                    @media (min-width: $breakpoint-alpha) {
                        display: none;
                    }
                }
            }

            .form-component.form-component-checkbox {
                padding:0 0 10px;
                label {
                    top:-8px;
                }
            }
            .form-component {
                padding-top:13px;
            }
            .cart-product-total {
                font-weight: 700;
                color: #000;
            }
            .product-title {
                color: $accent-color;
                font-weight: 700;
                display: inline-block;
            }
            .product-desc {
                color: #999;
                font-size: 14px;
            }
            .cell-qty {
                .number-input {
                    width:25px;
                    text-align: center;
                    display: inline-block;
                    border-bottom:0;
                    font-size: 18px;
                    font-weight: 700;
                    color: #999;
                }
                .number-input-control  {
                    display: inline-block;
                    position: relative;
                    top:1px;
                    color: #999;
                    -webkit-transition: color .3s ease;
                    -o-transition: color .3s ease;
                    transition: color .3s ease;
                    &:hover {
                        color: $accent-color;
                    }
                }
            }


            td {
                text-align: left;
                padding: .25em .65em;
                position: relative;
                .cell-content {
                    display: inline-block;
                    margin-left: 0;
                    width:auto;
                }
                &.cart-product-remove {
                    width:100%;
                    display: inline-block;
                    .form-component.form-component-checkbox label {
                        top: 5px;
                        span {
                            display: inline-block;
                        }
                    }
                }
                &.cart-product-name {
                    display: inline-block;
                    width:70%;
                    vertical-align: baseline;
                }
                &.cart-product-img {
                    width:30%;
                    display: inline-block;
                }
                &.cart-product-qty {
                    width:33%;
                    display: inline-block;
                    .cell-content .form-control {
                        padding:0;
                    }
                }
                &.cart-product-price {
                    width:33%;
                    display: inline-block;
                    text-align: left;
                }
                &.cart-product-total {
                    width:33%;
                    display: inline-block;
                    text-align: right;
                    .cell-content {
                        padding-top: 0;
                    }
                }
                @media (min-width: $breakpoint-alpha) {
                    display: table-cell;
                    padding: .25em .65em;
                    .cell-content {
                        display: block;
                        margin-left: 0;
                        width: auto;
                        &.cell-remove {
                            text-align: center;
                        }
                    }
                    &:first-child {
                        //padding-left: 0;
                    }

                    &:last-child {
                        //padding-right: 0;
                    }
                }

            }

            .cart-product-img {
                width:100%;
                height: auto;
            }
        }
}





.cart-table { 
    width:100%;
    &.confirmation-table {
        .cell-qty {
            width: auto !important;
        }
    }
}

.update-cart {
    margin-top: 20px;
}
.continue-shopping {
    margin-top: 20px;
}
@include media-breakpoint-down(sm) {
    .update-cart, 
    .continue-shopping {
        text-align: center !important;
    }
}

.additional-cart-boxes {
    margin-top: 40px;
    .form-component-coupon {
        display: none;
        .form-control {
            padding-right: 90px;
        }
        .form-control:focus {
            box-shadow:none;
            outline:0;
        }
        .apply-coupon {
            position: absolute;
            top: 22px;
            right: 2px;
            background: transparent;
            border: none;
            background: $accent-color;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            width: 85px;
            text-align: center;
            padding:12px 0;
            span {
                position: relative;
                color: $accent-color;
                -webkit-transition: transform .3s ease;
                -o-transition: transform .3s ease;
                transition: transform .3s ease;
            }
            &:hove r span {
                color: $accent-color;
            }
            span:after {
                content: '';
                position: absolute;
                bottom: -2px;
                height: 2px;
                background: $accent-color;
                width: 100%;
                left: 0;
                -webkit-transition: transform .3s ease;
                -o-transition: transform .3s ease;
                transition: transform .3s ease;
                -webkit-transform: scaleX(0);
                -ms-transform: scaleX(0);
                -o-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -ms-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }
            &:hover span:after {
                -webkit-transform: scaleX(1);
                -ms-transform: scaleX(1);
                -o-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .add-box {
            margin-top: 40px;
        }
    }
}

.cart-title {
    text-transform: none;
    font-size: 20px;
    font-weight: 700;
    color: #303030;
    margin-bottom: 20px;
}

.coupon-holder {
    margin-top: 15px;
}

.totals {
    .subtotal {
        border-top:1px solid #ddd;
        border-bottom:1px solid #ddd;
        padding:5px 0;
        .label {
            font-weight: 700;
            color: #000;
        }
        .price {
            font-weight: 700;
            color: #999;
        }
    }
    .middle {
        padding:7px 0;
        .label {
            .shipping-method {
                font-weight:700;
                color: #999;
            }
        }
        .price {
            font-weight: 700;
            color: #000;
        }
    }
    .total {
        border-top:2px solid #bbb;
        padding:5px 0;
        .label {
            font-weight: 700;
            color: #000;
        }
        .price {
            font-weight: 700;
            color: $accent-color;
        }
    }
}

.modal {
    -webkit-animation: zoomIn;
    animation: zoomIn;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index:9000;
    top:10%;
    right:0;
    position: fixed;
    left:0;
    background:#fff;
    padding:20px 20px;
    width:80%;
    z-index:9001;
    margin:auto;
    border-radius:1px;
    display: none;
    max-height: 80%;
    overflow-y: auto;
    .modal-header {
        border-bottom:1px solid #ddd;
        padding-bottom: 15px;
        margin-bottom: 20px;
        .heading {
            text-transform: none;
            margin: 0;
        }
    }
    .model-content {
    }
    .modal-footer {
        text-align: right;
        margin-top: 40px;
    }
}

.backdrop {
    display: none;
    position: fixed;
    top:0;
    left:0;
    right:0;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,.9);
    -webkit-animation: fadeIn;
    animation: fadeIn;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    z-index:9000;
}



.add-box.checkout {
    margin-top: 60px;
    .btn-checkout {
        font-size: 1.2em;
        padding-top:15px;
        padding-bottom:15px;
        position: relative;
    }
}

.btn-confirm {
    position: relative;
    .progress {
            position: absolute;
            bottom:-1px;
            width:100%;
            left:0;
            margin-bottom: 0;
            -webkit-transform: scaleY(0);
            -ms-transform: scaleY(0);
            -o-transform: scaleY(0);
            transform: scaleY(0);
            -webkit-transition: transform .3s ease;
            -o-transition: transform .3s ease;
            transition: transform .3s ease;
            -webkit-transform-origin: bottom center;
            -moz-transform-origin: bottom center;
            -ms-transform-origin: bottom center;
            -o-transform-origin: bottom center;
            transform-origin: bottom center;
        }
        &.loading {
            pointer-events: none;
            cursor: default;
            background: #999;
        }
        &.loading .progress {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            -o-transform: scaleY(1);
            transform: scaleY(1);
        }
    i {
        font-size: .8em;
        margin-right: 10px;
    }
}


@media(max-width: 768px) {
    .terms-box .btn-confirm-order-holder {
        text-align: left;
    }
}

.no-transform {
    text-transform: none;
}

.order-complete {
    margin-top: 40px;
    margin-bottom: 40px;
    .checkmark-svg {
        width:200px;
        margin:60px auto;
        margin-top: 0;
        .checkmark {
            stroke: $accent-color;
            stroke-dashoffset: 745.74853515625;
            stroke-dasharray: 745.74853515625;
            animation: dash ease 4.5s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            -webkit-animation: dash ease 4.5s;
            -webkit-animation-iteration-count: 1;
            -webkit-transform-origin: 50% 50%;
            -moz-animation: dash ease 4.5s;
            -moz-animation-iteration-count: 1;
            -moz-transform-origin: 50% 50%;
            -o-animation: dash ease 4.5s;
            -o-animation-iteration-count: 1;
            -o-transform-origin: 50% 50%;
            -ms-animation: dash ease 4.5s;
            -ms-animation-iteration-count: 1;
            -ms-transform-origin: 50% 50%;animation-fill-mode: forwards
        }
    }
    .order-complete-wrapper {
        text-align: center;
        padding:60px 0;
        .order-complete-heading {
            font-weight: 300;
            font-size:52px;
            text-transform: none;
        }
        .order-complete-subheading {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
        }
        .order-complete-details {
            margin-top: 40px;
            span {
                color: #999;
            }
            .buttons {
                margin-top: 40px;
            }
        }
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 745.74853515625;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

.form-half-part {
    .login-buttons {
        margin-top: 40px;
    }
    .title {
        margin-bottom: 10px;
    }
    &:not(:last-child) {
        border-right:1px solid #ddd;
    }
    @media(max-width:768px) {
        height: auto !important;
        &:not(:last-child) {
            border-right:none;
            margin-bottom: 30px;
        }
    }
}

.remember-me-holder {
    padding-top:5px;
}

.social-login-list {
    list-style: none;
    padding:0;
    margin:0;
    display: inline-block;
    li {
        display: inline-block;
        margin-left: 10px;
        position: relative;
        top:2px;
        a {
            display: inline-block;
            width:44px;
            height: 44px;
            line-height: 47px;
            color: #fff;
            text-align: center;
            border-radius: 50%;
            -webkit-transition: all .5s ease;
            -o-transition: all .5s ease;
            transition: all .5s ease;
            &:hover {
                box-shadow: 0px 4px 6px 2px rgba(0,0,0,.08);
            }
            &.facebook {
                background: #3b5998;
            }
            &.twitter {
                background: #4099FF;
            }
        }
    }
}

/* ================================ */
/* =========== PRODUCT ============ */
/* ================================ */
.product-container {
    .section__main_title {
        margin-top: 0;
    }
    .big-images-holder {
        position: relative;
        .product-note {
            position: absolute;
            z-index:8;
            text-align: center;
            &.not-in-stock {
                padding:5px 0;
                background:rgba(0,0,0,.2);
                color: #fff;
                bottom:20px;
                width:calc(100% - 50px);
                left:25px;
                text-transform: uppercase;
                color: #444;
                font-size: 12px;
                font-weight: 700;
            }
            &.discount {
                border-radius: 50%;
                width:60px;
                height: 60px;
                line-height: 60px;
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                background: $accent-color;
                top:25px;
                left:30px;
                box-shadow:0px 4px 15px rgba(0,0,0,.08);
            }
        }
        .big-images {
            .big-image {
                a {
                    display: block;
                    width:100%;
                    padding:10px 15px;
                    img {
                        border:1px solid #eee;
                        -webkit-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        transition: all .3s ease;
                        width:100%;
                    }
                    &:hover {
                        img {
                            box-shadow: 0 3px 10px 0 rgba(0,0,0,.06);
                        }
                    }
                }
            }
        }
        .big-image-preview {
            position: absolute;
            right:-350px;
            width:300px;
            height: 300px;
            z-index:2;
            pointer-events:none;
            top:50px;
        }
    }
    .small-images {
        overflow-x:auto;
        width:100%;
        .small-image {
            display: inline-block;
            a {
                padding:10px 15px;
                display: block;
                img {
                    border:1px solid #eee; 
                    -webkit-transition: all .3s ease;
                    -o-transition: all .3s ease;
                    transition: all .3s ease;
                    width:100%;
                }
                &:hover {
                    img {
                        box-shadow: 0 4px 10px 0 rgba(0,0,0,.06);
                    }
                }
            }
        }
    }
    .btn-add-to-cart.disabled {
        pointer-events: none;
        background: #aaa;
    }
}

.contents {
    padding:0;
    margin:0; margin-bottom: 20px;
    list-style: none;
    li {
        margin-bottom: 10px;
        .count {
            color: #999;
            width:25px;
            display: inline-block;
            margin-right: 5px;
            font-size: 14px;
            font-weight: 700;
            &:after {
                content:'x';
                position: relative;
                display: inline-block; 
                top:0px;
                left:2px;
            }
        }
    }
}

.price-holder {
    display: inline-block;
    float: left;
    .price {
        font-size: 48px;
        font-weight: 300;
        color: $accent-color;
        .old-price {
            font-size: 16px;
            font-weight: 700;
            color: #bbb;
            text-decoration: line-through;
        }
    }
}

.cart-counter {
    display: inline-block;
    float: right;
    margin-bottom: 40px;
    .form-component {
        .number-input-control {
            display: inline-block;
            position: relative;
            top:1px;
        }
        .form-control {
            display: inline-block;
            width:40px;
            padding-left: 0;
            padding-right: 0;
            border:none;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
        }
    }
}

.related-products, .product-category-holder {
    margin-bottom: 40px;
    .product, .related-product {
        background: #f3f3f3;
        padding-bottom:15px;
        text-align: center;
        margin-bottom: 20px;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        &:hover {
            box-shadow: 0 5px 10px 0 rgba(0,0,0,0.06);
        }
        img {
            width:100%;
            margin-bottom: 20px;
        }
        .product-name {
            font-size: 1.1em;
            max-width:90%;
            margin:0 auto;
            a {
                color: #333;
            }
        }
        .price {
            display: block;
            font-weight: 300;
            color: $accent-color; 
            font-size: 1.5em;
            margin: 10px 0;
        }
    }
}
.nav-tabs {
    margin-bottom: 15px;
    border-bottom: none;
    li {
        border-radius:0;
        a {
            border:none;
            background: transparent;
            border-bottom:2px solid transparent;
            -webkit-transition: border-color .3s ease;
            -o-transition: border-color .3s ease;
            transition: border-color .3s ease;
            color: #bbb;
            &:hover, &:focus {
                border-color:#bbb;
                background:transparent;
            }
            .span-badge {
                display: inline-block;
                width:16px;
                height: 16px;
                text-align: center;
                line-height: 16px;
                margin-left: 5px;
                color: #fff;
                font-size: 12px;
                background: $accent-color;
                border-radius: 50%;
            }
        }
        &.active {
            a {
                border:none;
                color: $accent-color;
                border-bottom:2px solid $accent-color;
                &:hover, &:focus {
                    color: $accent-color;
                    border:none;
                     border-bottom:2px solid $accent-color;
                }
            }
        }
    }
}
.nav-tabs-single {
    border-bottom:none;
    li {
        a {
            border:none !important;
            padding:0;
            margin-bottom: 15px;
            font-weight: 300;
            font-size: 24px;

        }
        &.active a {
            color: #666;
        }
    }
}

/* Product Category */
.product-category-filter {
    padding-bottom: 20px;
    text-align: center;
    button {
        margin:0 5px;
        margin-bottom: 10px;
    }
}

// Wizard
.wizard-form {
    background: transparent;
    box-shadow: none;
}

.wizard {
    .wizard-holder {
        padding:15px 25px;
        background: #eee;
        margin-top: 20px;
        box-shadow:0px 3px 25px 6px rgba(0,0,0,.15);
        position: relative;
       /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f2f2f2+0,f1f1f1+50,eeeeee+51,f6f6f6+100 */
background: rgb(242,242,242); /* Old browsers */
background: -moz-linear-gradient(-45deg,  rgba(242,242,242,1) 0%, rgba(241,241,241,1) 50%, rgba(238,238,238,1) 51%, rgba(246,246,246,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg,  rgba(242,242,242,1) 0%,rgba(241,241,241,1) 50%,rgba(238,238,238,1) 51%,rgba(246,246,246,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg,  rgba(242,242,242,1) 0%,rgba(241,241,241,1) 50%,rgba(238,238,238,1) 51%,rgba(246,246,246,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#f6f6f6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

        .reset-form {
            top:-20px;
            left:-20px;
            position: absolute;
            width:40px;
            height: 40px;
            text-align: center;
            line-height: 43px;
            font-size: 12px;
            border-radius: 50%;
            box-shadow:0px 3px 25px 6px rgba(0,0,0,.15);
            background: #666;
            color: #fff;
            -webkit-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background: $accent-color;
            }
        }
        .bar {
            content:'';
            position: absolute;
            bottom:0px;
            left:0px;
            width:0%;
            height:4px;
            background: $accent-color;
            -webkit-transition: width .4s ease;
            -o-transition: width .4s ease;
            transition: width .4s ease;
        }
    }
    .wizard-owl {
        position: relative;
        .item {
            padding:15px 15px;
            .title {
                padding: 30px 0;
                .heading {
                    &:before {
                        content:attr(data-counter);
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 900;
                        color: #fff;
                        width:40px;
                        height: 40px;
                        text-align:center;
                        line-height:40px;
                        border-radius:50%;
                        background: #999;
                        position: relative;
                        top:-8px;
                        margin-right: 20px;
                    }
                    font-family: 'Source Sans Pro';
                    font-size: 28px;
                }
            }
            .answer-toggle {
                -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;
                width:180px;
                height: 45px;
                margin-bottom: 40px;
                background: #ddd;
                position: relative;
                border-radius: 2px;
                cursor:pointer;
                display: inline-block;
                &:before {
                    content:'';
                    display: inline-block;
                    position: absolute;
                    top:4px;
                    left:4px;
                    width:calc(50% - 8px);
                    height: calc(100% - 8px);
                    background: $accent-color;
                    border-radius: 2px;
                    -webkit-transition: box-shadow .3s ease, transform .4s ease;
                    -o-transition: box-shadow .3s ease, transform .4s ease;
                    transition: box-shadow .3s ease, transform .4s ease;
                    -webkit-transform: translateX(calc(100% + 8px));
                    -ms-transform: translateX(100%) translateX(8px);
                    -o-transform: translateX(calc(100% + 8px));
                    transform: translateX(calc(100% + 8px));
                }
                &:hover:before {
                    box-shadow: 0px 0px 6px rgba(0,0,0,.2);
                    box-shadow: 0px 0px 6px rgba(0,0,0,.2);
                }
                &.active:before {
                    -webkit-transform: translateX(0);
                    -ms-transform: translateX(0);
                    -o-transform: translateX(0);
                    transform: translateX(0);
                }
                .yes {
                    position: absolute;
                    left:0;
                    display:inline-block;
                    top:0;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: 700;
                    height: 45px;
                    line-height: 45px;
                    width:50%;
                    text-align: center;
                    content:'Yes';
                    /*border-right: 1px solid #ccc;*/
                    z-index:2;
                    -webkit-transition: color .3s ease;
                    -o-transition: color .3s ease;
                    transition: color .3s ease;
                }
                .no {
                    position: absolute;
                    right:0;
                    display:inline-block;
                    top:0;
                    height: 45px;
                    line-height: 45px;
                    width:50%;
                    text-align: center;
                    content:'No';
                    color: #fff;
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: 700;
                    z-index:2;
                    -webkit-transition: color .3s ease;
                    -o-transition: color .3s ease;
                    transition: color .3s ease;
                }
                &.active  {
                    .yes {
                        color: #fff;
                    }
                    .no {
                        color: #666;
                    }
                }
            }
            .answer-choice {
                &.unanswered {
                    .answer-toggle {
                        &:before {
                            opacity: 0;
                            -webkit-transform:translateX(50%);
                            -ms-transform:translateX(50%);
                            -o-transform:translateX(50%);
                            transform:translateX(50%);
                        }
                        .yes, .no {
                            color: #666;
                        }
                    }
                }
            }
            .submit-holder {
                position: relative;
                .btn {
                    margin: 60px 0;
                    padding: 15px 65px;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 900;
                }
            }
            input[type="checkbox"] {
                display: none;
            }
            @media(max-width:768px) {
                padding: 30px 15px;
                .title {
                    padding:15px 0;
                    .heading {
                        font-size: 18px;
                    }
                }
                &.wizard-submit .submit-holder {
                    input {
                        margin: 0;
                    }
                }
            }
            &.wizard-disclaimer {
                padding:50px 30px;
                p {
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: 500;
                }
                a {
                    font-weight: 700;
                }
                .answer {
                    padding: 10px 0 !important;
                    label {
                        color: #111 !important;
                    }
                }
                @media(max-width:768px) {
                    padding: 15px 15px;
                    p {
                        font-size: 16px;
                        line-height: 24px
                    }
                }
            }
            &.slider {
                .answer {
                    padding: 5px 20px;
                }
                .slider-amount {
                    margin: 15px 0;
                    font-size: 24px;
                    font-weight: 700;
                }
                .answer-input {
                    display: none;
                }
                .btn {
                    margin-top: 80px;
                }
            }
        }
    }
}

.wizard-owl-controls {
    text-align: center;
    .owl-next, .owl-prev {
        display: inline-block;
        color: #bbb;
        font-size: 18px;
        margin:0 6px;
        cursor:pointer;
    }
    .owl-dot {
        width:15px;
        height: 15px;
        border-radius:50%;
        margin:0 3px;
        display:inline-block;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        border:3px solid #bbb;
        cursor:pointer;
        &.active, &:hover {
            border:3px solid $accent-color;
            background: $accent-color;
        }
    }
}

#testModal {
    max-width:500px;
    text-align: center;
    .product-title {
        text-transform: uppercase;
        color: #000;
        font-weight: 700;
        font-size: 24px;
    }
    .product-img {
        margin:20px 0;
        margin-bottom: 30px;
    }
}

// Front Page
.hero-section {
    margin-top: 30px;
    .left-side {
        height: 400px;
        position: relative;
        background:url('../img/left-side-bg.jpg') no-repeat center center;
        background-size: cover;
        .text {
            position: absolute;
            top:50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            text-shadow:1px 1px 1px rgba(0,0,0,.5);
            right:0;
            padding-right: 40px;
            @media (max-width:768px) {
                padding-right: 0;
                text-align: center;
            }
            h2 {
                font-size: 36px;
                font-family: 'Source Sans Pro';
                line-height: 1.5;
                font-weight: 300;
                b {
                    font-weight: 700;
                }
                color: #fff;
            }
            p {
                font-size: 16px;
                color: #fff;
            }
        }
    }
    .btn-right-side-holder {
        position: relative;
        
        .btn-right-side-buy {
                position: absolute;
                width:100%;
                padding:20px 0;
                box-shadow: none;
                box-shadow: none;
                bottom:0;
                left:0;
                width:calc(100% - 1.875rem);
                left:.9375rem;
            }
    }
    .right-side {
        height: 400px;
        position: relative;
        background:url('../img/right-side-bg.jpg') no-repeat center center;
        background-size: cover;
        .text {
            position: absolute;
            top:50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            text-shadow:1px 1px 1px rgba(0,0,0,.5);
            right:0;
            text-align: center;
            width:100%;
            padding:0 10px;
            @media (max-width:768px) {
                padding-right: 0;
                text-align: center;
            }
            h2 {
                font-size: 28px;
                font-family: 'Source Sans Pro';
                line-height: 1.5;
                font-weight: 300;
                b {
                    font-weight: 700;
                }
                color: #fff;
            }
            p {
                font-size: 16px;
                color: #fff;
            }
        }
    }
}

.features-section {
    padding:30px 0;
    .feature {
        box-shadow: 0 1px 2px rgba(0,0,0,.1);
        background: #f3f3f3;
        padding:20px 0;
        text-align: center;
        font-size: 13px;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        border-radius: 2px;
        &:hover {
            box-shadow: 0 2px 4px rgba(0,0,0,.15);
        }
        .icon {
            position: relative;
            left: -10px;
            top: 2px;
            font-size: 16px;
        }
    }
}

.product-container {
    .product-slider {
        position: relative;
        .owl-buttons {
            margin-top:0;
            position: absolute;
            top:calc(50% - 20px);
            width:100%;
            .owl-prev {
                float: left;
                margin-left: -40px;
            }
            .owl-next {
                float: right;
                margin-right: -40px;
            }
            @media(max-width:810px) {
                display: none;   
            }
        }
        .product-holder {
            padding:0 15px;
        }
    }
}

.slider-section {
    padding:100px 0;
    .slider-connect {
        text-align: center;
        margin-bottom: 40px;
        .number, .total {
            color: $accent-color;
        }
    }
    #slider {
        width:90%;
        position: relative;
        left:5%;
    }
}

.reviews-section {
    padding:40px 0;
    .review {
        box-shadow: 0 1px 2px rgba(0,0,0,.1);
        background: #fafafa;
        padding:10px 30px;
        padding-bottom: 30px;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        border-radius: 2px;
        border:1px solid transparent;
        position: relative;
        &:hover {
            box-shadow: 0 2px 4px rgba(0,0,0,.15);
            border-color:$accent-color;
        }
        @media(max-width:545px) {
            height:auto !important;
        }
        .review-title {
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 20px;
        }
        .review-content {

        }
        .reviewer {
            font-style: italic;
            font-size: 12px;
            text-align: right;
            position: absolute;
            bottom:10px;
            right:20px;
        }
    }
    .section__main_title {
        margin:0;
        margin-bottom: 40px;
    }
}

.text-section {
    padding:40px 0;
    .heading {
        font-weight: 700;
        font-size: 24px;
    }
    .section__main_title {
        margin:0;
    }
    img {
        max-width: 100%;
    }
    .content {
        .slide-links {
            padding:20px 0;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            a {
                display: inline-block;
                &:after {
                    content:"|";
                    display: inline-block;
                    margin:0 5px;
                }
                &:last-child:after {
                    content:"";
                }
            }
        }
        text-align: center;
        display: block;
        text-align: left;
        margin: 40px 0;
        padding:20px 20px;
        box-shadow: 0 1px 2px rgba(0,0,0,.1);
        background: #fbfbfb;
    }
}

.clients-section {
    padding:100px 0;
    .client {
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        &:hover {
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
        }
        @media (max-width:768px) {
            text-align: center;
            margin-bottom: 15px;
        }
        img {
            max-width: 100%;
        }
    }
}

.go-to-test {
    display: block;
    padding:15px 35px;
    font-size: 26px;
    font-weight: 700;
    margin:70px auto;
    width:300px;
}






/* DEMO */
.product-holder.not-in-stock {
    opacity: .4;
    .btn-add-to-cart {
        pointer-events: none;
        display: none;
        background: #bbb;
    }
    .product-img {
        position: relative;
        display: inline-block;
        width:100%;
        &:after {
            position: absolute;
            display: inline-block;
            width:80%;
            left:10%;
            bottom:50px;
            background: rgba(0,0,0,.6);
            color: #fff;
            content:'Not in stock.';
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 14px;
            padding:5px 0;
        }
    }
}

.payment-icon {
    display: inline-block;
    width:55px;
    vertical-align: top;
    margin-left: 10px;
    max-width:50px;
    max-height:20px;
    width:50px;
    height:20px;
}

.section-carousel-holder {
    position: relative;
    .section-carousel {
        cursor: move;
        .links {
            text-align: center;
            padding:30px 0 10px 0;
            a {
                display: inline-block;
                margin:0 4px;
            }
        }
    }
    .slide-prev {
        position: absolute;
        left:-50px;
        font-size: 28px;
        color: #333;
        top:150px;
    }
    .slide-next {
        position: absolute;
        font-size: 28px;
        color: #333;
        top:150px;
        right:-50px;
    }
    @media (max-width:768px) {
        .slide-prev, .slide-next {
            display: none;
        }
    }
}

/* Side Menu */
.side-menu {
    padding:15px 20px;
    .side-menu-title {
        margin-bottom: 20px;
    }
    ul {
        list-style-type: none;
        margin:0;
        padding:0;
        margin-top: 5px;
        li {
            a {
                font-size: 14px;
                color: #999;
                &.active {
                    font-weight: 700;
                    color: $accent-color;
                }
            }
        }
    }
}

.account-section {
    margin-bottom: 40px;
    .account-section-title {
        margin-bottom: 0px;
    }
}

.addresses-section {
    .address {
        margin:20px 0;
        margin-bottom: 35px;
        .address-title {
            padding:5px 0;
            border-bottom:1px solid #bbb;
            margin-bottom: 15px;
            .heading {
                font-weight: 700;
                font-size: 20px;
                float: left;
                margin:0;
            }
            .buttons {
                float: right;
                a {
                    display: inline-block;
                    -webkit-transition: color .3s ease;
                    -o-transition: color .3s ease;
                    transition: color .3s ease;
                    color: #666;
                    margin-left: 10px;
                    &:hover {
                        color: $accent-color;
                    }
                }
            }
            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        span {
            display: block;
        }
    }
}

.margin-top {
    margin-top:15px;
}

.order-info {
    .order-info-left {
        display: inline-block !important;
        font-weight: 700;
        color: #111;
    }
    .order-info-right {
        display: inline-block !important; 
        margin-left: 10px;
        &.order-info-price {
            color:$accent-color;
        }
    }
}

.orders-section {
    .order {
        margin:20px 0;
        margin-bottom: 35px;
        .order-title {
            padding:5px 0;
            border-bottom:1px solid #bbb;
            margin-bottom: 15px;
            .heading {
                font-weight: 700;
                font-size: 20px;
                float: left;
                margin:0;
            }
            .buttons {
                float: right;
                a {
                    display: inline-block;
                    -webkit-transition: color .3s ease;
                    -o-transition: color .3s ease;
                    transition: color .3s ease;
                    color: #666;
                    margin-left: 10px;
                    &:hover {
                        color: $accent-color;
                    }
                }
            }
            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }

        span {
            display: block;
        }
    }
}

.order-buttons {
    a {
        margin-left: 10px;
    }
}

.top-product {
    margin:40px 0;
    .top-product-img {
        width:100%;     
    }
    .top-title {
        text-align: center;
        background: $accent-color;
        padding:20px 0;
        margin-bottom: 30px;
        .heading {
            color: #fff;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 700;
        }
    }
    .accent-title {
        color: $accent-color;
        .big-percent {
            font-weight: 700;
            font-size: 32px;
            float: left;
            margin-right: 10px;
            color: $accent-color;
        }
        .text {
            float: left;
            font-size: 14px;
            font-weight: 500;
            color: $accent-color;
        }
        &:after {
            content:'';
            display: table;
            clear: both;
        }
    }
    .buttons {
        margin-top: 30px;
    }
}

.chart-canvas {
    margin:60px 0;
}

.icon-status {
    font-size: 18px;
    margin-right: 15px;
    display: inline-block;
    padding:13px 10px;
    position: relative;
    top:5px;
    &:before {
        position: relative;
        top:-4px;
    }
    &.icon-status-pending {
        color: #999;
        border-color: #999;
    }
    &.icon-status-processing {
        color: #0ff;
        border-color: #0ff;
    }
    &.icon-status-complete {
        color: $accent-color;
        border-color: $accent-color;
    }
    &.icon-status-cancelled {
        color: #f00;
        border-color: #f00;
    }
}

.isotope-grid {
    .item {
        position: relative;
        background-size: cover;
        background-position:center center;
        .content {
            padding:0 15px;
            position: relative;
            &.center-white {
                text-align: center;
                color: #fff;
                .heading {
                    color: #fff;
                }
                p {
                    color: #fff;
                }
            }
        }
        &.new-year {
            text-align: center;
            color: #fff;
            background: $accent-color;
            .heading {
                font-size: 18px;
                color: #fff;
            }
            p {
                font-size: 13px;
                color: #fff;
            }
        }
        &.item-percent {
            text-align: center;
            &:after {
                content:'';
                width:100%;
                height: 100%;
                position: absolute;
                top:0;
                left:0;
                border:5px solid $accent-color;
            }
            .big-percent {
                color: $accent-color;
                font-weight: 700;
                font-size: 32px;
                margin: 0;
            }
        }
        &.item-newsletter {
            text-align: center;
            background: url('../img/clean.jpg');
            .heading {
                font-weight: 700;
                color: #fff;
            }
            p {
                color: #fff;
            }
            input {
                color: #fff;
                border:0;
                border-bottom:2px solid #fff;
                background:transparent;
                margin-bottom:10px;
                &:focus {
                    outline:0;
                }
            }
        }
        &.item-order-ship {
            background: $accent-color;
            text-align: center;
            .heading {
                margin:0;
                margin-bottom: 10px;
                color: #fff;
            }
            p {
                color: #fff;
                span {
                    display:block;
                    font-size: 24px;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
        &.item-video {
            padding:0;
            iframe {
                width:100%;
                height: 100%;
                position: absolute;
                border:0;
                outline:0;
            }
        }
    }
}

.timeago {
    &:after {
        content: " ( " attr(datetime) " )";
        width:30px;
    }
}


.sticky-add-to-cart {
    display: none !important;
    @media(max-width:768px) {
        display: inline-block !important;
        position: fixed;
        bottom:0;
        left:0;
        width:100%;
        border-radius: 0 !important;
        z-index:500;
        font-size: 14px;
        text-transform: uppercase;
        padding:15px 0;
    }
}

@media(max-width:768px) {
    .product-details .btn-add-to-cart {
        display: none;
    }
}   

.contact-addresses {
    span {
        font-weight: 700;
        display: block;
        &.email {
            margin-top:10px;
        }
    }
}

.btn-contact, .btn-order {
    padding:18px 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
}

.arrow-title {
    background: $accent-color;
    text-align: center;
    padding:20px 40px;
    position: relative;
    .heading {
        color: #fff;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
        margin:0;
        margin-bottom: 10px;
    }
    &:after {
        content:'';
        width:0;
        height:0;
        border:15px solid transparent;
        border-top:15px solid $accent-color;
        position: absolute;
        bottom:-30px;
        left:calc(50% - 15px);
    }
    p {
        color: #fff;
        margin-bottom: 0;
        font-size: 14px;
    }
}

.alert-dismissible .close {
    position: relative;
    top: -.125rem;
    right: -1rem;
    color: inherit;
}
.alert-dismissible button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
}
.alert-dismissible .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
}

.remove-discount-button {
    background:transparent;
    border:none;
    box-shadow:none;
}

.shipment-table, .notes-table {
    width:100%;
    tr {
        &:not(.header) td {
            font-weight: 500;
        }
        &.header {
            td {
                font-weight: 700;
            }
        }
    }
}

.shipment-details {
    padding: 40px 40px;
    .shipment-detail-title {
        margin-top: 40px;
        margin-bottom: 10px;
        border-bottom:1px solid #bbb;
        font-size: 32px;
    }
    .shipment-detail {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 40px;
        }
    }
}



.responsive-table {
  margin: 1em 0;
  min-width: 100%; // adjust to your needs
  
  tr {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    &:nth-child(odd) {
        background: #ddd;
    }
    &:nth-child(even) {
        background: #eee;
    }
  }
  
  th {
    display: none; 
  }
  
  td {
    display: block; 
    padding-left: 20px;
    padding-right: 20px;
    &:first-child {
      padding-top: .5em;
    }
    &:last-child {
      padding-bottom: .5em;
    }

    &:before {
      content: attr(data-th)": "; 
      font-weight: bold;

      
      width: 6.5em; 
      display: inline-block;
      // end options
      
      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }
  
  th, td {
    text-align: left;
    
    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: .25em .5em;
      
      &:first-child {
        padding-left: .5em;
      }
      
      &:last-child {
        padding-right: 0;
      }
    }

  }
  
  
}

.container-big-padding {
    padding:300px 0;
}

.availability {
    display: inline-block;
    margin:10px 0;
    label {
        font-size: 12px;
    }
    span {
        background: $accent-color;
        display: inline-block;
        color: #fff;
        padding:2px 8px;
        border-radius:5px;
        font-size: 12px;
        margin-left: 10px;
    }
}

.stock-notification {
    input[type="text"], button {
        display: inline-block;
    }
}

.stock-sub-table {
    .checkbox-label {
        position: relative;
        top:-15px !important;
    }
    margin-bottom: 40px;
}

/* YOUTUBE PLAYER START */
.youtube {
    background-color: #000;
    margin-bottom: 30px;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
}
.youtube img {
    width: 102%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
}
.youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}
.youtube .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}
.youtube img,
.youtube .play-button {
    cursor: pointer;
}
.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
    position: absolute;
}
.youtube .play-button,
.youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
}
.youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
.youtube .youtube-centered-image,
.youtube .youtube-centered-image:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
}

/* YOUTUBE PLAYER END */


.step-navigation button.btn.btn-next {
    background: #535555;
}
.step-navigation button.btn {
    color: #fff;
    display: inline-block;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.step-navigation button.btn.btn-next:hover {
    background: #444;
}

.alert-dismissible .close {
    position: relative;
    top: -.125rem;
    right: -1rem;
    color: inherit;
}
.alert-dismissible button.close {
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-appearance: none;
}
.alert-dismissible .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
}

.add-box.checkout {
    margin-top: 20px;
}

.address-boxes {
    margin-top: 20px;
}

.step-navigation {
    padding: 20px 0px;
    margin-bottom: 0px;
}

.bordered {
    transition: all .4s ease;
    border: 1px solid transparent;
}
.bordered:hover {
    border: 1px solid transparent;    
    box-shadow: 0 2px 4px rgba(0,0,0,.15);
    border-color: #e19dc1;
}
.shippping-method-logo,
.payment-method-logo {
    float: left;
    width: 100px;
    height: auto;
    margin-right: 10px;
}

.burger-menu__wrapper .user-holder ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.burger-menu__wrapper .user-holder ul li {
    text-align: left;
}

.remove-coupon {
    border: none;
    color: $accent-color;
    background:transparent;
    border:none;
    box-shadow:none;    
}

.steps {
    margin-top:20px;
}

span.xsmoke-order-price {
    color: $accent-color;
    font-weight: 700;
    display: inline !important;
}
span.shipping-method {
    display: inline !important;
}
span.cart-empty-spinner {
    font-weight: 700;
    text-align: center;
    display: inline-block;
    padding: 7px 0;
}
.old-price-in-category {
    font-size: 16px;
    font-weight: 700;
    color: #bbb;
    text-decoration: line-through;
}

.category-product-note.category-product-discount-circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
    background: $accent-color;
    top: 25px;
    left: 30px;
    box-shadow: 0 4px 15px rgba(0,0,0,.15);
}
.category-product-note {
    position: absolute;
    z-index: 10;
    text-align: center;
}

.product-description {
    max-width: 90%;
    margin: 0 auto;
}
.menu-item-tall{
    padding-top: 5px;
    padding-bottom: 5px;    
}
.plain-page-big-text{
    text-align: center;
    padding: 20px 0;
    font-weight: 300;
    font-size: 52px;
    text-transform: none;   
}

.no-margin-on-top{
    margin-top: 0;
}

.timeago:after {
    content: "";
}


.lum-lightbox {
    z-index:9999;
}
.btn {
    border-radius: 2px;
}
#product-detail-tabs {
    padding-left: 15px;
    padding-right: 15px;
}

.availability {
  display: inline-block;
  margin: 10px 0;
}
.availability label {
  font-size: 12px;
}
.availability span {
  background: $accent-color;
  display: inline-block;
  color: #fff;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 10px;
}
#notie-alert-text a {
    color: #FFFFFF;
    position: relative;
    &:after {
        content:'';
        position: absolute;
        bottom:-2px;
        height: 2px;
        background: #fff;
        width:100%;
        left:0;
    }
}

.hide {
    display: none;
}

.section__main img.section__main_banner {
    margin-bottom: 0px;
}

.goto-cart-div:hover #cart-svg {
  stroke-dashoffset: 0;
}

.goto-cart {
    position: relative;
    &:after {
        content:'';
        position: absolute;
        bottom:-2px;
        height: 2px;
        background: #fff;
        width:100%;
        left:0;
        -webkit-transition: transform .3s ease;
        -o-transition: transform .3s ease;
        transition: transform .3s ease;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -ms-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }
    &:hover {
        color:$accent-color;
        &:after {
            -webkit-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}

.modal {
    height: 80%;
}

.product-container {
    .btn-add-to-cart {
        margin-bottom: 10px;
    }
}

.modal-content {
    height: calc(100% - 60px);
}

.modal .modal-footer {
    margin-top: 20px;
}

#iframe-terms-of-service {
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    width: 100%;
    height: 100%;
}




@media(max-width:1069px) {
    .rwd-table.no-product-img {
  margin: 1em 0;
  min-width: 100%;
}
.rwd-table.no-product-img tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.rwd-table.no-product-img tr:nth-child(odd) {
  background: #ddd;
}
.rwd-table.no-product-img tr:nth-child(even) {
  background: #eee;
}
.rwd-table.no-product-img th {
  display: none;
}
.rwd-table.no-product-img td {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  width:100%;
}
.rwd-table.no-product-img td:after {
  clear: both;
  display: table;
  content: '';
}
.rwd-table.no-product-img td .cell-content {
  margin-top: 5px;
  padding-left:15px;
  width:calc(100% - 6.5em);
  float: left;
}
.rwd-table.no-product-img td:first-child {
  padding-top: .5em;
}
.rwd-table.no-product-img td:last-child {
  padding-bottom: .5em;
}
.rwd-table.no-product-img td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block;
  position: relative;
  float: left;
  word-wrap:break-word;
}
@media (min-width: 1069px) {
  .rwd-table.no-product-img td:before {
    display: none;
  }
}
.rwd-table.no-product-img td .cell-content {
  display: inline-block;
}
.rwd-table.no-product-img td .cell-qty .form-component {
  padding: 0;
}
.rwd-table.no-product-img th, .rwd-table.no-product-img td {
  text-align: left;
}
@media (min-width: 1069px) {
  .rwd-table.no-product-img th, .rwd-table.no-product-img td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table.no-product-img th:first-child, .rwd-table.no-product-img td:first-child {
    padding-left: .5em;
  }
  .rwd-table.no-product-img th:last-child, .rwd-table.no-product-img td:last-child {
    padding-right: 0;
  }
}

}

.cart-counter {
    margin-bottom: 0px;
}

@media (max-width: 768px) {
    .breadcrumbs {
        padding: 0 20px 0;
    }
}

$circle-dim: 200px;
.age-modal {
    overflow-x: hidden;
    height:100%;
    max-height:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    background: transparent;
    .age-title {
        position: absolute;
        bottom:50%;
        left:50%;
        width:80%;
        @include prefix(transform, translateX(-50%));
        text-align: center;
        color: #fff;
        font-size: 36px;
        padding-bottom: 50px;
        @media(max-width:768px) {
            font-size: 20px;
        }
    }
    .half-circle {
        width:$circle-dim / 2;
        height:$circle-dim;
        position: absolute;
        box-shadow:0px 0px 100px rgba(#fff, 1);
        cursor: pointer;
        margin-top: 50px;
        .age-holder {
            font-size: 32px;
            font-weight: 700;
            color: #111;
            position: absolute;
            top:50%;
            left:50%;
            @include prefix(transform, translateX(-50%) translateY(-50%));
            z-index:6;
        }
    }
    .half-circle.left {
        background: #fff;
        border-radius: $circle-dim 0 0 $circle-dim;
        top: 50%;
        left: calc(50% - 100px);
        @include prefix(transition, all .4s ease);
        &:hover {
            background: #bbb;
        }
    }
    .half-circle.right {
        background: $accent-color;
        border-radius: 0 $circle-dim $circle-dim 0;
        top: 50%;
        left: 50%;
        @include prefix(transition, all .4s ease);
        z-index: 5;
        &:hover {
            background: #e19dc1;
        }
    }
    .helper {
        position: absolute;
        color: #fff;
        top: calc(50% + 150px);
        @include prefix(transform, translateY(-50%));
        .icon {
            margin:0 20px;
        }
        &.helper-left {
            right:calc(50% + 150px);
        }
        &.helper-right {
            left:calc(50% + 150px);
        }
    }
    @media(max-width:768px) {
        .age-title {
            bottom:50%;
            padding-bottom: 0;
        }
        .half-circle {
            width:100px;
            height:50px;
            box-shadow:none;
            text-align: center;
            margin-top: 40px;
        }
        .half-circle.left {
            border-radius: 3px 0 0 3px;
            top: 50%;
            left: calc(50% - 100px);
            z-index: 5;
            color: #111;
            @include prefix(transition, all .4s ease);
            .age-holder {
                position: relative;
                color: #111;
            }
        }
        .half-circle.right {
            border-radius: 0 3px 3px 0;
            top: 50%;
            left: 50%;
            @include prefix(transition, all .4s ease);
            .age-holder {
                position: relative;
                color: #fff;
            }
        }
        .helper {
            position: absolute;
            top: calc(50% + 100px);
            color: #fff;
            @include prefix(transform, translateY(0));
            font-size: 14px;
            .icon {
                display: none;
            }
            &.helper-left {
                right:calc(50% + 20px);
                z-index:10;
            }
            &.helper-right {
                left:calc(50% + 20px);
                z-index:10;
            }
        }
    }
}

.btn-shave { display: block;}

.trustpilot-img {
    display: block;
    margin:0 auto;
    max-width:80%;
    margin-bottom: 40px;
    @media(max-width:768px) {
        width:150px;
    }
}

body.pattern-bg {
    background: url('../img/pattern.jpg') repeat center center;
}
.hero-layout {
    text-align: center;
    .heading {
        font-size: 64px;
        margin: 40px 0;
    }
    .subheading {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        color: #999;
    }
    img.full-size {
        width: 100%;
        margin: 80px 0;
        margin-bottom: 0;
    }
    p.img-desc {
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 60px;
    }
    .timer-heading {
        font-size: 48px;
        font-weight: 300;
        .timer {
            font-weight: 700;
            color: $accent-color;
            display: block;
        }
    }
    .btn-hero {
        max-width:600px;
        width:100%;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        padding: 25px 40px;
        margin: 60px 0;
    }
}

.product-bundle-list {
    list-style: none;
    padding-left: 0px;
    >li {
        margin-bottom: 20px;
        .bundle-img {
            width:64px;
        }
        .media-body {
            overflow:visible;
        }
        .bundle-heading {
            font-weight: 700;
        }
        .bundle-description {
            color: #999;
            font-size: 14px;
            margin-bottom: 5px;
        }
        .form-component-dropdown {
            width:100%;
            max-width:400px;
        }
        .prices {
            .old-price {
                color: #bbb;
                text-decoration: line-through; 
                margin-right: 10px;
            }
            .new-price {
                color: $accent-color;
                font-weight: 700;
                font-size: 20px;
            }
        }
    }
}

.complete-payment {
    margin-top: 15px;
    .btn {
        margin-bottom: 15px;
    }
    p {
        color: #bbb;
        font-size: 14px;
    }
}

.complete-payment-sticky {
    position: fixed;
    bottom:0;
    left:0;
    width:100%;
    background:#fff;
    padding:15px 25px;
    text-align: left;
    z-index:500;
    box-shadow:0px -5px 15px rgba(0,0,0,.1);
    .btn {
        width:100%;
        margin-bottom: 10px;
    }
    p {
        font-size: 14px;
        color: #bbb;
        margin-bottom: 0;
    }
}

.purchase-notice {
    margin: 20px 0;
    background: $accent-color;
    padding: 20px 35px;
    font-weight: 600;
    color: #fff;
    border-radius: 2px;
    box-shadow: 0px 5px 14px rgba(0,0,0,0.07);
}

@media(min-width:768px) {
    .complete-payment-sticky {
        display: none;
    }
}

@media(max-width:768px) {
    .complete-payment {
        display: none;
    }
    .order-buttons {
        margin-top: 15px;
        .btn:first-child {
            margin-left: 0;
        }
    }
}