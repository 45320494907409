@import "./node_modules/bootstrap/scss/_functions";
@import "./node_modules/bootstrap/scss/_mixins";
@import "./node_modules/bootstrap/scss/_variables";

// Reset and dependencies
@import "./node_modules/bootstrap/scss/_print";

// Core CSS
@import "./node_modules/bootstrap/scss/_reboot";
@import "./node_modules/bootstrap/scss/_grid";

// Utility classes
@import "./node_modules/bootstrap/scss/_utilities.scss";


.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-5ths {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        width: 20%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        width: 20%;
        float: left;
    }
}